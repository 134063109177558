import React, { Component } from 'react'
import './Touit.css';

export default class Touit extends Component {
  render() {
    const {pseudo, message, left} = this.props;

    return (
    left === 0 ? (
        <div className='touit touit-left'>
            <p className='touit-pseudo'>{pseudo} :</p>
            <p className='touit-message'>{message}</p>
        </div>
    ) : (
        <div className='touit touit-right'>
            <p className='touit-pseudo'>{pseudo} :</p>
            <p className='touit-message'>{message}</p>
        </div>
    )
    )
  }
}
