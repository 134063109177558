export default class TouiteurAPI {
    static async getAllTouits(ts){
        const response = await fetch('https://touiteur.cefim-formation.org/list?ts='+ts);
        let data = await response.json();
        data = Object.entries(data.messages)
        return data;
    }
    
    static async getTrendings(){
       const response = await fetch('https://touiteur.cefim-formation.org/trending');
       const data = await response.json();
       return data;
   }
   
   static async getInfluencings(){
    const response = await fetch('https://touiteur.cefim-formation.org/influencers?count=10');
    let data = await response.json();
    data = Object.entries(data.influencers);
    return data;
    }

    static async postTouit(ev) {
        ev.preventDefault();
        const response = await fetch("https://touiteur.cefim-formation.org/send?", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(new FormData(ev.target)),
        });
        if (response.ok) {
            alert("Votre revendication a bien été envoyée");
        } else {
            alert("Une erreur est survenue");
        }
    }
}