import React, { Component } from 'react'
import uniqid from 'uniqid';
import './SendMessageForm.css';
import TouiteurAPI from '../api/TouiteurAPI.js';

export default class SendMessageForm extends Component {
    constructor(props){
      super(props);
      this.globalId = uniqid();
      this.state = {
        message: ''
      }
    }

  checkInput = (ev) => {
    if (ev.target.parentElement.name.value.length >= 3 && ev.target.parentElement.message.value.length >= 3) {
      ev.target.parentElement.submit.disabled = false;
    } else {
      ev.target.parentElement.submit.disabled = true;
    }
  }

  handleMessage = (ev) => {
    this.setState({message: ev.target.value});
  }

  postTouit = (ev) => {
    ev.preventDefault();
    TouiteurAPI.postTouit(ev);
    this.setState({message: ''});
  }
  render() {
    const {message} = this.state;

    return (
      <div className="container">
        <form className='send-message-form' onSubmit={this.postTouit} onChange={this.checkInput}>
          <label htmlFor={this.globalId + "_username"}>Votre Pseudo</label>
          <input type="text" id={this.globalId + "_username"} name="name" placeholder="Pseudo (3 - 16)" maxLength={16} className='pseudo-form' required />
          <label htmlFor={this.globalId + "_message"}>Votre Revendication</label>
          <input type="text" id={this.globalId + "_message"} name="message" placeholder="Revendication (3 - 256)" maxLength={256} className="message-form" required value={message} onChange={this.handleMessage}/>
          <input type="submit" name="submit" value="Revendiquer" disabled={true} />
        </form>
      </div>
    )
  }
}
