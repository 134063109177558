import { Component } from 'react';
import Header from "./components/Header";
import Trending from "./components/Trending";
import Influencing from "./components/Influencing";
import SendMessageForm from './components/SendMessageForm';
import TouitContainer from './components/TouitContainer';
class App extends Component {

  render(){

    return (
      <>
        <Header />
        <Trending />
        <Influencing />
        <SendMessageForm />
        <TouitContainer />
      </>
    );
  }
}

export default App;
