import React, { Component } from 'react'

export default class Influencer extends Component {
  render() {
    const {influencer, value} = this.props
    return (
      <>
        <a href="#">{influencer} ({value})</a>
      </>
    )
  }
}
