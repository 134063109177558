import React, { Component } from 'react';
import './Trending.css';
import TouiteurAPI from "../api/TouiteurAPI.js";
import Trend from './Trend';

export default class Trending extends Component {
  constructor(props){
    super(props);
    this.state = {
      trendingData: []
    }
  }
  async componentDidMount(){
    const trendingData = await TouiteurAPI.getTrendings();
    this.setState({ trendingData });
  }
  render() {
    let {trendingData} = this.state;

    return (
    <div className='container'>
      <div className='trendings'>
        <h2>Principales Revendications :</h2>
        {trendingData = Object.entries(trendingData).sort((a, b) => b[1] - a[1]).slice(0, 10).map(t => 
          <Trend trend={t[0]} value={t[1]} key={t[0]+t[1]} />)}
      </div>
    </div>
    )
  }
}
