import React, { Component } from 'react';
import TouiteurAPI from "../api/TouiteurAPI.js";
import './Influencing.css';
import Influencer from './Influencer';

export default class Influencing extends Component {
  constructor(props){
    super(props);
    this.state = {
      influencingData: []
    }
  }

  async componentDidMount(){
    const influencingData = await TouiteurAPI.getInfluencings();
    this.setState({ influencingData });
  }
  render() {
    let {influencingData} = this.state;
    influencingData = influencingData.sort((a, b) => (b[1].messages + b[1].comments) - (a[1].messages + a[1].comments));
 
    return (
    <div className='container'>
      <div className='influencing'>
        <h2>Principaux Grévistes :</h2>
        {influencingData.map(i =>
            <Influencer influencer={i[0]} value={i[1].messages + i[1].comments} key={i[0]+i[1].messages+i[1].comments} />)}
      </div>
    </div>
    )
  }
}
