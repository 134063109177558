import React, { Component } from 'react'

export default class Trend extends Component {
  render() {
    const {trend, value} = this.props
    return (
      <>
        <a href="#">{trend} ({value})</a>
      </>
    )
  }
}
