import React, { Component } from 'react'
import TouiteurAPI from '../api/TouiteurAPI.js';
import Touit from './Touit';

export default class TouitContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            touits: [],
        }
        this.timestamp = 0;
    }
    componentDidMount(){
        this.fetchTouits();
    }

    async fetchTouits(){
        let {touits} = this.state;
        const touitsData = await TouiteurAPI.getAllTouits(this.timestamp);
        if(touits.length !== touitsData.length && touitsData.length > 0){
            this.setState({touits: touits.concat(touitsData).sort((a, b) => b[1].ts - a[1].ts)});
        }
        if(touitsData.length !== 0){
            this.timestamp = touitsData.sort((a, b) => b[1].ts - a[1].ts)[0][1].ts;
        }
        setTimeout(this.fetchTouits.bind(this), 5000);
    }

  render() {
    let {touits} = this.state;
    return (
      <section className='touit-container'>
        <div className='container'>
            {touits.map((touit, index) => 
            <Touit pseudo={touit[1].name} message={touit[1].message} key={touit[1].id} left={index % 2 }/>)}
        </div>
      </section>
    )
  }
}
