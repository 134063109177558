import React, { Component } from 'react'
import './Header.css';

export default class Header extends Component {
  render() {
    return (
      <header>
        <div className="container">
            <h1><a href="#"><span className='cursive'>la</span> cgt <span className='cursive'>ouit</span></a></h1>
        </div>
      </header>
    )
  }
}
